import { AssetCategory, AssetStatus, AssetSubStatus } from '@src/enums'
import { Contract, Issuer, Platform } from '@src/types'

export class Asset {
    asset_uid: string | null = null
    asset_location_city: string | null = null
    asset_location_state: string | null = null
    asset_location_country: string | null = null
    clearing_system: 'CI' | 'CF' | 'EMISSION' | null = null
    asset_title: string | null = null
    platform: Platform | null = null
    platform_name: string | null = null
    issuer_id: string | null = null
    issuer: Issuer | null = null
    category: AssetCategory | null = null
    funding_min: number | null = 0
    investment_min: number | null = 0
    currency: string | null = null
    investment_max: number | null = 0
    funding_max: number | null = 0
    iban: string | null = null
    bic: string | null = null
    step_level: number | null = null
    contract_period: string | null = null
    funding_count: number | null = null
    funding_total: number | null = null
    base_funding_count: number | null = null
    base_funding_total: number | null = null
    manual_funding_totals = false
    deactivate_payment_account = false
    tags: string | null = null
    installment_investment = false
    installment_payout = false
    minimum_initial_payment: number | null = null
    maximum_initial_payment: number | null = null
    initial_payment_step: number | null = null
    minimum_installment: number | null = null
    maximum_installment: number | null = null
    installment_step: number | null = null
    minimum_monthly_rates: number | null = null
    maximum_monthly_rates: number | null = null
    minimum_yearly_rates: number | null = null
    maximum_yearly_rates: number | null = null
    interest_rate: number | null = null
    contract_range: string | null = null
    stripe_credentials: StripeCredentials | null = null
    payment_methods: PaymentMethods = new PaymentMethods()
    signature_manual = false
    signature_sproof = false
    require_qualified_signature = false
    asset_documents: AssetDocument[] = []
    signature_legal_notice: string | null = null
    emission_signature_document: EmissionSignatureDocument | null = null
    contract: Contract | null = null
    voucher_contract: Contract | null = null
    contracts: Contract[] = []
    created_on: string | null = null
    updated_at: string | null = null
    status: AssetStatus | null = null
    sub_status: AssetSubStatus | null = null
}

export class AssetDocument {
    id: string | null = null
    name: string | null = null
    description: string | null = null
    url: string | null = null
    type: number | null = null
    upload_key: string | null = null
}

export class EmissionSignatureDocument {
    id: string | null = null
    name: string | null = null
    description: string | null = null
    url: string | null = null
    type: number | null = null
    upload_key: string | null = null
}

export class PaymentMethods {
    credit_card = false
    wire_transfer = false
}

export class StripeCredentials {
    publishable_key: string | null = null
    secret_key: string | null = null
}
