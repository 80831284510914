import { VoucherifyVoucher } from '@src/types/VoucherifyVoucher'
import { EmissionSignature } from './Signatures'

export class ExternalKycProcess {
    id: null | string = null
    external_id: null | string = null
    status: null | string = null
    natural_profile_overwritten_at: string | null = null
    created_at: null | string = null
    updated_at: null | string = null
    result_summary_url: null | string = null
    result_data_package_url: null | string = null
}

export class NaturalProfile {
    natural_profile_uid: null | string = null
    sso_user_uid: null | string = null
    platform_name: null | string = null
    sub_platform_name: null | string = null
    active = false
    first_name: null | string = null
    last_name: null | string = null
    email: null | string = null
    gender: null | string = null
    date_of_birth: null | string = null
    nationality: null | string = null
    social_security_number: null | string = null
    tax_number: null | string = null
    address: null | string = null
    postal_code: null | string = null
    city: null | string = null
    country: null | string = null
    state: null | string = null
    phone_number: null | string = null
    mobile_number: null | string = null
    pep = false
    pep_past = false
    pep_position: null | string = null
    pep_past_position: null | string = null
    kyc_id = false
    kyc_address = false
    kyc_bank = false
    kyc_tax = false
    custom_id: null | string = null
    account_uid: null | string = null
    newsletter_consent = false
    email_pending: null | string = null
    bankAccounts: BankAccount[] = []
    bic: null | string = null
    iban: null | string = null
    wallet: Wallet = new Wallet()
    created_on: null | string = null
    updated_at: null | string = null
    last_login_date: null | string = null
    owner = false
    external_kyc_processes: ExternalKycProcess[] = []
    postal_address: null | string = null
    postal_address_postal_code: null | string = null
    postal_address_city: null | string = null
    postal_address_state: null | string = null
    postal_address_country: null | string = null
    signatures: EmissionSignature[] = []
    ecsp_status: null | string = null
    compliance_profile: null | string = null
    compliance_profiles: null | object = null
    default_language: null | string = null
    membership_number: null | string = null
    membership_document: MembershipDocument[] = []
}

export class EcspInvestorClassification {
    expert_investor_treatment: null | string = 'yes'
    self_disclosure: null | object = null
}

export class EcspExperience {
    no_answer = false
    financial_education: null | string = null
    bonds_knowledge: null | string = null
    bonds_investment_frequency: null | string = null
    stocks_knowledge: null | string = null
    stocks_investment_frequency: null | string = null
    convertible_bonds_knowledge: null | string = null
    convertible_bonds_investment_frequency: null | string = null
}

export class EcspRisk {
    no_answer = false
    startup_investing: null | string = null
    investments_outside_stock_market: null | string = null
    subordinated_financial_instruments: null | string = null
    profit_participation_certificate: null | string = null
}

export class EcspInvestmentGoals {
    no_answer = false
    sustainability: null | string = null
    investment_goal: null | string = null
    investment_horizon: null | string = null
}

export class EcspFinancialSituation {
    annual_net_income: null | string = null
    liquid_funds: null | string = null
    annual_financial_commitments: null | string = null
    net_worth: null | string = null
    loss_bearing_capacity: null | string = null
}

export class EcspComplianceProfileNatural {
    investor_classification: EcspInvestorClassification =
        new EcspInvestorClassification()
    experience: EcspExperience = new EcspExperience()
    risk: EcspRisk = new EcspRisk()
    investment_goals: EcspInvestmentGoals = new EcspInvestmentGoals()
    financial_situation: EcspFinancialSituation = new EcspFinancialSituation()
}

export class EcspComplianceProfileLegal {
    investor_classification: EcspInvestorClassification =
        new EcspInvestorClassification()
    risk: EcspRisk = new EcspRisk()
    financial_situation: EcspFinancialSituation = new EcspFinancialSituation()
}

export class Wallet {
    id: null | string = null
    status = 0
    balance = 0
    retrievable_balance = 0
    blocked_balance_from_paid_orders = 0
    blocked_balance_from_orders = 0
    blocked_balance_from_pending_withdrawals = 0
    external_id: null | string = null
    currency: null | string = null
    currency_symbol: null | string = null
    deposit_account_holder: null | string = null
    deposit_iban: null | string = null
    deposit_bic: null | string = null
    deposit_payment_reference: null | string = null
    moneyWithdrawals: MoneyWithdrawal[] = []
}

export class MoneyWithdrawal {
    id: null | string = null
    status: null | string = null
    amount = 0
    comment: null | string = null
    was_started_from_frontend = false
    created_at: null | string = null
    updated_at: null | string = null
    bankAccount: BankAccount = new BankAccount()
}

export class CreateMoneyWithdrawal {
    amount = 0
    bank_account_id = null
    comment = null
    natural_profile_id: null | string = null
}

export class LegalProfile {
    legal_profile_uid: null | string = null
    natural_profile_uid: null | string = null
    account_uid: null | string = null
    company_name: null | string = null
    description: null | string = null
    website: string | null = null
    registration_number: null | string = null
    tax_number: null | string = null
    address: null | string = null
    postal_code: null | string = null
    city: null | string = null
    country: null | string = null
    state: null | string = null
    iban: null | string = null
    bic: null | string = null
    created_on: null | string = null
    updated_at: null | string = null
    kyc_legal = false
    kyc_bank = false
    platform_has_payment_account = false
    wallet: Wallet = new Wallet()
    vouchers: VoucherifyVoucher[] = []
    bankAccounts: BankAccount[] = []
    beneficialOwners: BeneficialOwner[] = []
    postal_address: null | string = null
    postal_address_postal_code: null | string = null
    postal_address_city: null | string = null
    postal_address_state: null | string = null
    postal_address_country: null | string = null
    signatures: EmissionSignature[] = []
    company_email: null | string = null
    membership_number: null | string = null
    membership_document: MembershipDocument[] = []
}

export class BankAccount {
    id: null | string = null
    holder: null | string = null
    iban: null | string = null
    status: null | string = null
    is_main = null
    created_at: null | string = null
    kyc_documents: BankKYCDocument[] = []
}

export class BankKYCDocument {
    id: null | string = null
    account_id: null | string = null
    verification_type: null | string = null
    type: null | string = null
    storage_key: null | string = null
    url: null | string = null
    mime_type: null | string = null
    valid_to: null | string = null
    verification_status: null | string = null
    verification_comment: null | string = null
    verification_date: null | string = null
    is_external = false
    is_manual: boolean | null = null
    created_at: null | string = null
    bankAccount: BankAccount = new BankAccount()
    temp_storage_url: null | string = null
}

export class KYCDocument {
    id: null | string = null
    account_id: null | string = null
    verification_type: null | string = null
    type: null | string = null
    storage_key: null | string = null
    url: null | string = null
    mime_type: null | string = null
    valid_to: null | string = null
    verification_status: null | string = null
    verification_comment: null | string = null
    verification_date: null | string = null
    is_external = false
    is_manual: boolean | null = null
    created_at: null | string = null
    bankAccount = null
    temp_storage_url: null | string = null
}

export class ECSPDocument {
    id: null | string = null
    account_id: null | string = null
    key: null | string = null
    url: null | string = null
    mime_type: null | string = null
    verification_comment: null | string = null
    verification_date: null | string = null
    ecsp_status: null | string = null
    created_at: null | string = null
    temp_storage_url: null | string = null
}

export class MembershipDocument {
    id: null | string = null
    key: null | string = null
    url: null | string = null
    natural_profile_id: null | string = null
    legal_profile_id: null | string = null
    status: null | string = null
    created_at: null | string = null
    temp_storage_url: null | string = null
}

export class Transaction {
    id: null | string = null
    external_id = 0
    type = 0
    date: null | string = null
    from: null | string = null
    to: null | string = null
    amount = 0
    amount_real = 0
    status = 0
    method = 0
    order_id: null | string = null
    payment_message: null | string = null
    sender_natural_profile: NaturalProfile | null = null
    sender_legal_profile: LegalProfile | null = null
    receiver_natural_profile: NaturalProfile | null = null
    receiver_legal_profile: LegalProfile | null = null
}

export class BeneficialOwner {
    first_name: null | string = null
    last_name: null | string = null
    nationality: null | string = null
    city_of_birth: null | string = null
    country_of_birth: null | string = null
    country_of_residence: null | string = null
    date_of_birth: string | number | Date | null = null
    email: null | string = null
    gender: null | string = null
    active = false
    signatures: EmissionSignature[] = []
}

export class Address {
    address: null | string = null
    zip: null | string = null
    city: null | string = null
    country: null | string = null
    state: null | string = null
}
