<template>
    <PageWrapper>
        <template #headline>
            <fa
                icon="fa-person"
                title="natural_account"
            />
            {{ $t('account_title') }}: {{ account.account_title }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/accounts'"
                icon="fa-list"
                title="accounts"
                @click="toAccounts"
            />
            <EditButton
                v-if="userStore.can('update_account')"
                title="edit"
                @click="editNaturalProfile"
            />
            <ActionsMenu
                v-if="
                    showCreateButton ||
                    userStore.can('delete_account') ||
                    userStore.can('create_order') ||
                    userStore.can('sync_wallet') ||
                    (!showCreateButton && userStore.can('create_account'))
                "
            />
        </template>

        <SummaryWrapper>
            <NaturalProfileSummary v-if="accountOverviewItemsLeft" />
            <template #sidebar>
                <LegalProfileSummary v-if="legalProfile.account_uid" />
                <PlatformSummary />
                <WalletSummary
                    v-if="naturalProfile.wallet && naturalProfile.wallet.id"
                />
            </template>
        </SummaryWrapper>

        <AccordionBasic
            title="orders"
            :add-to-title="userOrdersNum"
            class="mb-2"
            :open="true"
        >
            <OrdersTable
                v-if="naturalProfile.account_uid"
                :key="userOrdersNum"
                :initial-filters="{
                    account_uid: naturalProfile.account_uid,
                }"
            />
        </AccordionBasic>

        <AccordionBasic
            title="account_address_contact_details"
            :open="false"
        >
            <BillingAddressSummary />
            <template v-if="isPostalAddress">
                <PostalAddressSummary />
            </template>
        </AccordionBasic>

        <AccordionBasic
            title="account_bank_accounts"
            :open="false"
        >
            <BankAccountsTable />
            <Summary :items="bankAccountsDetailsItems" />
        </AccordionBasic>

        <AccordionBasic
            title="kyc_documents"
            :add-to-title="
                idKYCDocuments.length +
                taxKYCDocuments.length +
                addressKYCDocuments.length +
                bankKYCDocuments.length
            "
            class="mb-2"
            :open="false"
        >
            <KYCIdTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createIdKYCDocument"
                    />
                </template>
            </KYCIdTable>

            <KYCAddressTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createAddressKYCDocument"
                    />
                </template>
            </KYCAddressTable>

            <KYCBankTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createBankKYCDocument"
                    />
                </template>
            </KYCBankTable>

            <KYCTaxTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createTaxKYCDocument"
                    />
                </template>
            </KYCTaxTable>
        </AccordionBasic>
        <AccordionBasic
            v-if="
                platform.compliance_profile === PlatformComplianceProfile.ECSP
            "
            title="ecsp_document"
            :add-to-title="idECSPDocuments.length"
            class="mb-2"
            :open="false"
        >
            <ECSPDocumentTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createECSPDocumentModal"
                    />
                </template>
            </ECSPDocumentTable>
        </AccordionBasic>

        <AccordionBasic
            v-if="platform.membership_management_active === true"
            title="membership_management"
            class="mb-2"
            :open="false"
        >
            <MembershipDocumentTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createMembershipDocumentModal"
                    />
                </template>
            </MembershipDocumentTable>
        </AccordionBasic>

        <AccordionBasic
            v-if="externalKycDetailsItems.length > 0"
            title="external_kyc_details"
            :open="false"
        >
            <ExternalKycSummary />
        </AccordionBasic>

        <AccordionBasic
            title="tax_details"
            :open="false"
        >
            <TaxDetailsTable />
        </AccordionBasic>

        <AccordionBasic
            v-if="naturalProfile.wallet && naturalProfile.wallet.id"
            title="wallet_transactions"
            :add-to-title="userTransactionsCount"
            class="mb-2"
            :open="false"
        >
            <TransactionsTable
                :initial-filters="{
                    natural_profile_id: naturalProfile.natural_profile_uid,
                }"
                @get-count="getTransactionsCount"
            />
        </AccordionBasic>

        <AccordionBasic
            v-if="naturalProfile.wallet && naturalProfile.wallet.id"
            title="wallet_money_withdrawals"
            :add-to-title="moneyWithdrawalsNum"
            class="mb-2"
            :open="false"
        >
            <WithdrawalTable>
                <template #actions>
                    <CreateButton @click.stop="createMoneyWithdrawal" />
                </template>
            </WithdrawalTable>
        </AccordionBasic>
        <AccordionBasic
            v-if="signatures.length > 0"
            title="signatures"
            :add-to-title="signatures.length"
            class="mb-2"
            :open="false"
        >
            <SignaturesTable />
        </AccordionBasic>
        <AccordionBasic
            v-if="voucherifyVouchers.length > 0"
            title="vouchers"
            :add-to-title="voucherifyVouchers.length"
            class="mb-2"
            :open="false"
        >
            <VoucherifyVouchersTable />
        </AccordionBasic>

        <template #modals>
            <CreateLegalAccountModal
                v-if="createLegalProfileModalOpen"
                ref="createLegalAccountModal"
                v-model="tmpLegalProfile"
                v-model:open="createLegalProfileModalOpen"
                @submit="createLegalAccount"
                @cancel="closeCreateLegalAccountModal"
            />
            <CreateOrderSelectUserModal
                v-if="createOrderSelectUserModalOpen"
                ref="createOrderSelectUserModal"
                v-model="tmpOrder"
                v-model:open="createOrderSelectUserModalOpen"
                :preselected="account.account_uid"
                @submit="createOrder"
                @cancel="closeCreateOrderModal"
            />
        </template>
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
/* eslint-disable quote-props */
import { useVoucherifyVoucherTable } from '@composables/accounts/useVoucherifyVoucherTable'
import {
    ModalButtonStyles,
    useActionsMenu,
    useEnum,
    useMask,
    useModal,
    useSummary,
} from '@composables/common'
import EditButton from '@partials/actions/EditButton.vue'
import OrdersTable from '@partials/orders/OrdersTable.vue'
import { AccordionBasic, Summary, SummaryWrapper } from '@src/components'
import { useBankAccountsTable } from '@src/composables/accounts/useBankAccountsTable'
import { useECSPDocumentsTable } from '@src/composables/accounts/useECSPDocumentsTable'
import { useKYCBankDocumentsTable } from '@src/composables/accounts/useKYCBankDocumentsTable'
import { useKYCDocumentsTable } from '@src/composables/accounts/useKYCDocumentsTable'
import { useMembershipDocumentsTable } from '@src/composables/accounts/useMembershipDocumentsTable'
import { useWithdrawalTable } from '@src/composables/accounts/useWithdrawalTable'
import { useSignaturesTable } from '@src/composables/signatures/useSignaturesTable'
import { useAddressSummary } from '@src/composables/summaries/useAddressSummary'
import { usePlatformSummary } from '@src/composables/summaries/usePlatformSummary'
import { useWalletSummary } from '@src/composables/summaries/useWalletSummary'
import {
    EcspStatusType,
    ExternalKycStatus,
    NaturalProfileGenderType,
    ProfileType,
} from '@src/enums'
import AddressKYCDocumentCreateForm from '@src/partials/accounts/AddressKYCDocumentCreateForm.vue'
import BankKYCDocumentCreateForm from '@src/partials/accounts/BankKYCDocumentCreateForm.vue'
import CreateLegalAccountModal from '@src/partials/accounts/CreateLegalAccountModal.vue'
import ECSPDocumentCreateForm from '@src/partials/accounts/ECSPDocumentCreateForm.vue'
import IdKYCDocumentCreateForm from '@src/partials/accounts/IdKYCDocumentCreateForm.vue'
import MoneyWithdrawalCreateForm from '@src/partials/accounts/MoneyWithdrawalCreateForm.vue'
import TaxKYCDocumentCreateForm from '@src/partials/accounts/TaxKYCDocumentCreateForm.vue'
import BackButton from '@src/partials/actions/BackButton.vue'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import CreateOrderSelectUserModal from '@src/partials/orders/CreateOrderSelectUserModal.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import TransactionsTable from '@src/partials/transactions/TransactionsTable.vue'
import {
    Account,
    Address,
    BankAccount,
    BankKYCDocument,
    CreateMoneyWithdrawal,
    CreateOrderPayload,
    ECSPDocument,
    EcspComplianceProfileNatural,
    EcspInvestorClassification,
    EmissionSignature,
    KYCDocument,
    LegalProfile,
    MembershipDocument,
    MoneyWithdrawal,
    NaturalProfile,
    Order,
    Platform,
    VoucherifyVoucher,
    Wallet,
} from '@src/types'

import { PlatformComplianceProfile } from '@enums/Platform'
import MembershipDocumentCreateForm from '@partials/accounts/MembershipDocumentCreateForm.vue'
import {
    createLegalProfile as apiCreateLegalProfile,
    createMoneyWithdrawal as apiCreateMoneyWithdrawal,
    aprroveMembershipDocument,
    createECSPDocument,
    createKYCDocument,
    createMembershipDocument,
    deleteAccountNaturalGDPR,
    getAccount,
    getECSPDocuments,
    getKYCDocuments,
    getLegalProfilesFromNaturalProfile,
    getNaturalProfile,
    resetExternalKYCProcess,
    syncWallet,
    updateComplianceProfile,
} from '@src/utils/api/accounts'
import { createOrder as apiCreateOrder, getOrders } from '@src/utils/api/orders'
import { getPlatform } from '@src/utils/api/platforms'
import { getVoucherifyVouchers } from '@src/utils/api/voucherifyVouchers'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { computedAsync } from '@vueuse/core'
import moment from 'moment'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const isModalOpen = ref(false)
const { ModalWrapper, openModal } = useModal()
const toast = useToastStore()
const userStore = useUserStore()
const { everyFourSpace } = useMask()
const languageStore = useLanguageStore()

const account = ref<Account>(new Account())
const naturalProfile = ref<NaturalProfile>(new NaturalProfile())
const legalProfile = ref<LegalProfile>(new LegalProfile())
const userOrders = ref<Order[]>([])
const showCreateButton = ref<boolean>(false)
const bankAccounts = ref<BankAccount[]>([])
const moneyWithdrawals = ref<MoneyWithdrawal[]>([])
const KYCDocuments = ref<KYCDocument[]>([])
const ECSPDocuments = ref<ECSPDocument[]>([])
const membershipDocuments = ref<MembershipDocument[]>([])
const userTransactionsCount = ref(0)
const platform = ref<Platform>(new Platform())
const wallet = ref<Wallet>(new Wallet())
const countryStore = useCountryStore()
const voucherifyVouchers = ref<VoucherifyVoucher[]>([])

const addressKYCDocuments = ref<KYCDocument[]>([])
const idKYCDocuments = ref<KYCDocument[]>([])
const bankKYCDocuments = ref<BankKYCDocument[]>([])
const taxKYCDocuments = ref<KYCDocument[]>([])
const idECSPDocuments = ref<ECSPDocument[]>([])
const idMembershipDocuments = ref<MembershipDocument[]>([])
const limit = ref<number>(10)
const page = ref<number>(1)
const moneyWithdrawalsNum = ref(0)
const addressKYCDocumentsNum = ref(0)
const bankKYCDocumentsNum = ref(0)
const taxKYCDocumentsNum = ref(0)
const idKYCDocumentsNum = ref(0)
const userOrdersNum = ref(0)
const idECSPDocumentsNum = ref(0)
const idMembershipDocumentsNum = ref(0)
const i18n = useI18n()
const { getEnumLabel } = useEnum()
const createOrderSelectUserModal = ref(false)
const createOrderSelectUserModalOpen = ref(false)
const tmpOrder = ref<CreateOrderPayload>(new CreateOrderPayload())
const createLegalProfileModalOpen = ref(false)
const tmpLegalProfile = ref<LegalProfile>(new LegalProfile())
const tmpMoneyWithdrawal = ref(new CreateMoneyWithdrawal())
const newBankKYCDocument = ref(new BankKYCDocument())
const newKYCDocument = ref(new KYCDocument())
const newECSPDocument = ref(new EcspInvestorClassification())
const newMembershipDocument = ref(new MembershipDocument())
const platformUrl = ref<string>('')
const signatures = ref<EmissionSignature[]>([])

const { ActionsMenu } = useActionsMenu(
    {
        id: 'asset-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'button_delete_dsgvo',
            icon: 'fa-trash-alt',
            color: 'text-danger-500',
            action: handleOpenDeleteNaturalProfileModal,
            condition: () => userStore.can('delete_account'),
        },
        {
            label: 'button_kyc_reset_external_process',
            icon: 'fa-rotate-left',
            color: 'text-gray-500',
            action: () =>
                handleResetExternalKYCProcess(
                    naturalProfile.value.natural_profile_uid
                ),
            condition: () =>
                userStore.can('can_reset_external_kyc_process') &&
                naturalProfile.value.external_kyc_processes.length > 0 &&
                !naturalProfile.value.external_kyc_processes.find(function (
                    ekp
                ) {
                    return ekp.status === ExternalKycStatus.EXTERNAL_KYC_DONE
                }),
        },
        {
            label: 'modal_title_create_order',
            icon: 'fa-eye',
            color: 'text-gray-500',
            action: openCreateOrderSelectUser,
            condition: () => {
                return (
                    !!naturalProfile.value.wallet &&
                    userStore.can('create_order')
                )
            },
        },
        {
            label: 'button_sync_wallet',
            icon: 'fa-rotate',
            color: 'text-gray-500',
            action: openWalletSync,
            condition: () => {
                return (
                    !!naturalProfile.value.wallet &&
                    userStore.can('sync_wallet')
                )
            },
        },
        {
            label: 'legal_account_create',
            icon: 'fa-plus-circle',
            color: 'text-gray-500',
            action: handleOpenCreateLegalModal,
            condition: () => {
                return (
                    !showCreateButton.value && userStore.can('create_account')
                )
            },
        },
    ]
)

function handleOpenDeleteNaturalProfileModal() {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_dsgvo_modal',
            title: 'modal_title_delete_natural_profile',
            open: isModalOpen,
            onCancel: () => {
                isModalOpen.value = false
            },
            onSubmit: async () => deleteNaturalProfile(),
        },
        () => h('p', i18n.t('modal_text_delete_natural_profile')),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

async function handleResetExternalKYCProcess(natural_profile_uid: string) {
    try {
        await resetExternalKYCProcess(natural_profile_uid)
        toast.success(i18n.t('toast_success_kyc_processes_reset'))
        await loadAccount()
    } catch (error) {
        toast.error(i18n.t('toast_error_kyc_processes_reset'))
        throw error
    }
}

function openCreateOrderSelectUser(): void {
    createOrderSelectUserModalOpen.value = true
}

async function openWalletSync() {
    isModalOpen.value = true
    try {
        if (naturalProfile.value.wallet.id != null) {
            const walletId = naturalProfile.value.wallet.id
            await syncWallet(walletId)
            toast.success(i18n.t('toast_success_wallet_synced'))
            await loadAccount()
        } else {
            toast.error(i18n.t('toast_error_wallet_id_null'))
        }
    } catch (error) {
        toast.error(i18n.t('toast_error_wallet_sync_failed'))
    } finally {
        isModalOpen.value = false
    }
}

function handleOpenCreateLegalModal() {
    createLegalProfileModalOpen.value = true
}

const { WithdrawalTable } = useWithdrawalTable(
    moneyWithdrawals,
    moneyWithdrawalsNum
)

const { VoucherifyVouchersTable } =
    useVoucherifyVoucherTable(voucherifyVouchers)

const { PlatformSummary } = usePlatformSummary(platform)

const { KYCTable: KYCIdTable } = useKYCDocumentsTable(
    idKYCDocuments,
    openModal,
    isModalOpen,
    assignAndFilterKYCDocuments,
    'kyc_id_documents',
    'help_kyc_id_documents'
)

const { KYCTable: KYCAddressTable } = useKYCDocumentsTable(
    addressKYCDocuments,
    openModal,
    isModalOpen,
    assignAndFilterKYCDocuments,
    'kyc_address_documents',
    'help_kyc_address_documents'
)

const { KYCBankTable } = useKYCBankDocumentsTable(
    bankKYCDocuments,
    openModal,
    isModalOpen,
    assignAndFilterKYCDocuments,
    'kyc_bank_documents',
    'help_kyc_bank_documents'
)

const { ECSPDocumentTable } = useECSPDocumentsTable(
    idECSPDocuments,
    openModal,
    isModalOpen,
    assignAndFilterECSPDocuments,
    loadAccount,
    'ecsp_id_document',
    'help_ecsp_id_document'
)

const { MembershipDocumentTable } = useMembershipDocumentsTable(
    idMembershipDocuments,
    openModal,
    isModalOpen,
    assignAndFilterMembershipDocuments,
    loadAccount,
    'membership_id_document',
    'help_membership_id_document'
)

const { SignaturesTable } = useSignaturesTable(signatures)

async function saveMoneyWithdrawal(moneyWithdrawal: CreateMoneyWithdrawal) {
    moneyWithdrawal.natural_profile_id =
        naturalProfile.value?.natural_profile_uid
    try {
        await apiCreateMoneyWithdrawal(moneyWithdrawal)

        toast.success(i18n.t('toast_success_withdrawal_created'))
    } catch (error) {
        toast.error(i18n.t('toast_error_withdrawal_creation_failed'))
    }

    tmpMoneyWithdrawal.value = new CreateMoneyWithdrawal()

    isModalOpen.value = false
}

function createMoneyWithdrawal() {
    isModalOpen.value = true
    const disableCreate = ref(true)

    const onValidate = (isFormValid: boolean) => {
        disableCreate.value = !isFormValid
    }

    openModal(
        {
            id: 'create_money_withdrawal',
            title: 'modal_title_create_money_withdrawal',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () =>
                await saveMoneyWithdrawal(tmpMoneyWithdrawal.value),
        },
        () =>
            h(MoneyWithdrawalCreateForm, {
                modelValue: tmpMoneyWithdrawal,
                naturalProfile: naturalProfile.value,
                legalProfile: legalProfile.value,
                createForProfile: ProfileType.NATURAL,
                onValidate: (value: boolean) => onValidate(value),
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreate,
        }
    )
}

function createIdKYCDocument() {
    newKYCDocument.value = {
        ...new KYCDocument(),
        account_id: naturalProfile.value.account_uid,
        verification_type: 'kyc_id',
    }
    isModalOpen.value = true
    openModal(
        {
            id: 'create_id_kyc_document',
            title: 'modal_title_create_id_kyc_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveKYCDocument(newKYCDocument.value),
        },
        () =>
            h(IdKYCDocumentCreateForm, {
                modelValue: newKYCDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreateKYC,
        }
    )
}

function createECSPDocumentModal() {
    newECSPDocument.value = {
        ...new EcspInvestorClassification(),
    }
    isModalOpen.value = true
    openModal(
        {
            id: 'create_id_ecsp_document',
            title: 'modal_title_create_id_ecsp_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveECSPDocument(newECSPDocument.value),
        },
        () =>
            h(ECSPDocumentCreateForm, {
                modelValue: newECSPDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

function createMembershipDocumentModal() {
    newMembershipDocument.value = { ...new MembershipDocument() }
    isModalOpen.value = true
    openModal(
        {
            id: 'create_id_membership_document',
            title: 'modal_title_create_id_membership_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveMembershipDocument(newMembershipDocument.value),
        },
        () =>
            h(MembershipDocumentCreateForm, {
                modelValue: newMembershipDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

function createAddressKYCDocument() {
    newKYCDocument.value = {
        ...new KYCDocument(),
        account_id: naturalProfile.value.account_uid,
        verification_type: 'kyc_address',
    }

    isModalOpen.value = true
    openModal(
        {
            id: 'create_address_kyc_document',
            title: 'modal_title_create_address_kyc_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveKYCDocument(newKYCDocument.value),
        },
        () =>
            h(AddressKYCDocumentCreateForm, {
                modelValue: newKYCDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreateKYC,
        }
    )
}

const disableCreateKYC = computed(() => {
    return !newKYCDocument.value.type
})

function createBankKYCDocument() {
    newBankKYCDocument.value = {
        ...new KYCDocument(),
        bankAccount: {} as BankAccount,
        account_id: naturalProfile.value.account_uid,
        verification_type: 'kyc_bank',
    }

    isModalOpen.value = true
    openModal(
        {
            id: 'create_bank_kyc_document',
            title: 'modal_title_create_bank_kyc_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveBankKYCDocument(newBankKYCDocument.value),
        },
        () => h(BankKYCDocumentCreateForm, { modelValue: newBankKYCDocument }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreateBankKYC,
        }
    )
}

async function saveBankKYCDocument(document: BankKYCDocument) {
    try {
        await createKYCDocument(document)

        toast.success(i18n.t('toast_success_kyc_document_created'))
    } catch (error) {
        toast.error(i18n.t('toast_error_kyc_document_create'))
    }

    assignAndFilterKYCDocuments()
    isModalOpen.value = false
}

const disableCreateBankKYC = computed(() => {
    return !(
        newBankKYCDocument.value.bankAccount.iban &&
        newBankKYCDocument.value.type
    )
})

const { KYCTable: KYCTaxTable } = useKYCDocumentsTable(
    taxKYCDocuments,
    openModal,
    isModalOpen,
    assignAndFilterKYCDocuments,
    'kyc_tax_documents',
    'help_kyc_tax_documents'
)

function createTaxKYCDocument() {
    newKYCDocument.value = {
        ...new KYCDocument(),
        account_id: naturalProfile.value.account_uid,
        verification_type: 'kyc_tax',
    }

    isModalOpen.value = true
    openModal(
        {
            id: 'create_tax_kyc_document',
            title: 'modal_title_create_tax_kyc_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveKYCDocument(newKYCDocument.value),
        },
        () => h(TaxKYCDocumentCreateForm, { modelValue: newKYCDocument }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreateKYC,
        }
    )
}

async function saveKYCDocument(document: KYCDocument) {
    try {
        await createKYCDocument(document)

        toast.success(i18n.t('toast_success_kyc_document_created'))
    } catch (error) {
        toast.error(i18n.t('toast_error_kyc_document_create'))
        throw error
    }
    assignAndFilterKYCDocuments()
    isModalOpen.value = false
}

async function saveECSPDocument(document: ECSPDocument) {
    try {
        naturalProfile.value.compliance_profiles = {
            ecsp: new EcspComplianceProfileNatural(),
        }

        naturalProfile.value.compliance_profiles.ecsp.investor_classification.self_disclosure =
            {
                ...{
                    key: document.key,
                    mime_type: document.mime_type,
                    status: document.status,
                    temp_storage_url: document.temp_storage_url,
                },
            }

        document.account_id = naturalProfile.value.account_uid

        await createECSPDocument(document)

        await updateComplianceProfile(naturalProfile.value, ProfileType.NATURAL)
        // refreshing account after ecsp update
        await loadAccount()

        toast.success(i18n.t('toast_success_ecsp_document_updated'))
    } catch (error) {
        toast.error(i18n.t('toast_error_ecsp_document_create'))
        throw error
    }
    assignAndFilterECSPDocuments()
    isModalOpen.value = false
}

async function saveMembershipDocument(document: MembershipDocument) {
    try {
        document.natural_profile_id = naturalProfile.value.natural_profile_uid

        await createMembershipDocument(document)
        await loadAccount()

        toast.success(i18n.t('toast_success_membership_document_create'))
    } catch (error) {
        toast.error(i18n.t('toast_error_membership_document_create'))
        throw error
    }
    assignAndFilterMembershipDocuments()
    isModalOpen.value = false
}

const taxDetailsItems = computed<
    { label: string; value: string | object | unknown; special?: string }[]
>(() => {
    return [
        {
            label: i18n.t('tax_number'),
            value: naturalProfile.value.tax_number,
            copy: true,
        },
        {
            label: i18n.t('account_social_security_number'),
            value: naturalProfile.value.social_security_number,
            copy: true,
        },
        {
            label: i18n.t('account_tax_kyc_status'),
            value: naturalProfile.value.kyc_tax,
            special: 'check',
        },
    ]
})

const legalProfileOverviewItems = computed<
    { label: string; value: string | object | unknown; special?: string }[]
>(() => {
    return [
        {
            label: i18n.t('id'),
            value: legalProfile.value.legal_profile_uid,
            special: 'link',
            linkURL: '/legalProfile/' + legalProfile.value.legal_profile_uid,
            copy: true,
        },
        {
            label: i18n.t('profile_company_name'),
            value: legalProfile.value.company_name,
            special: 'link',
            linkURL: '/legalProfile/' + legalProfile.value.legal_profile_uid,
            copy: true,
        },
        {
            label: i18n.t('profile_company_description'),
            value: legalProfile.value.description,
        },
        {
            label: i18n.t('profile_company_details_kyc'),
            value: legalProfile.value.kyc_legal,
            special: 'check',
        },
        {
            label: i18n.t('account_bank_kyc_status'),
            value: legalProfile.value.kyc_bank,
            special: 'check',
        },
    ]
})

const accountOverviewItemsLeft = computedAsync<
    { label: string; value: string | object | unknown; special?: string }[]
>(async () => {
    return [
        {
            label: i18n.t('id'),
            value: naturalProfile.value.natural_profile_uid,
            copy: true,
        },
        {
            label: i18n.t('active'),
            value: naturalProfile.value.active,
            special: 'check',
        },
        {
            label: i18n.t('sso_user_uid'),
            value: naturalProfile.value.sso_user_uid,
            copy: true,
        },
        {
            label: i18n.t('custom_id'),
            value: naturalProfile.value.custom_id,
            copy: true,
            condition: naturalProfile.value.custom_id !== null,
        },
        {
            label: i18n.t('platform'),
            value: naturalProfile.value.platform_name,
            special: 'link',
            linkURL: `/platforms/${naturalProfile.value.platform_name}`,
            copy: true,
        },
        {
            label: i18n.t('sub_platform'),
            value: naturalProfile.value.sub_platform_name,
            special: 'link',
            linkURL: `/platforms/${naturalProfile.value.sub_platform_name}`,
            copy: true,
            condition: naturalProfile.value.sub_platform_name !== null,
        },
        {
            label: i18n.t('account_first_name'),
            value: naturalProfile.value.first_name,
            copy: true,
        },
        {
            label: i18n.t('account_last_name'),
            value: naturalProfile.value.last_name,
            copy: true,
        },
        {
            label: i18n.t('account_gender'),
            value: getEnumLabel(
                NaturalProfileGenderType,
                naturalProfile.value.gender
            ),
        },
        {
            label: i18n.t('date_of_birth'),
            value: moment(naturalProfile.value.date_of_birth).format(
                'DD.MM.yyyy'
            ),
        },
        {
            label: i18n.t('account_email'),
            value: naturalProfile.value.email,
            copy: true,
        },
        {
            label: i18n.t('email_pending'),
            value: naturalProfile.value.email_pending,
            copy: true,
            condition: naturalProfile.value.email_pending !== null,
        },
        {
            label: i18n.t('phone_number'),
            value: naturalProfile.value.phone_number,
            copy: true,
        },
        {
            label: i18n.t('mobile_number'),
            value: naturalProfile.value.mobile_number,
            copy: true,
        },
        {
            label: i18n.t('membership_number'),
            value: naturalProfile.value.membership_number,
            copy: true,
            condition:
                platform.value.membership_management_active === true &&
                platform.value.membership_number_active === true,
        },
        {
            label: i18n.t('account_nationality'),
            value: await countryStore.translate(
                naturalProfile.value.nationality as string
            ),
        },
        {
            label: i18n.t('account_default_language'),
            value: await languageStore.translate(
                naturalProfile.value.default_language as string
            ),
        },
        {
            label: i18n.t('account_kyc_status'),
            value: naturalProfile.value.kyc_id,
            special: 'check',
        },

        {
            label: i18n.t('kyc_external'),
            value:
                naturalProfile.value.external_kyc_processes[0]?.status ===
                'success',
            special: 'check',
            condition:
                naturalProfile.value.external_kyc_processes &&
                naturalProfile.value.external_kyc_processes.length > 0,
        },
        {
            label: i18n.t('account_address_kyc_status'),
            value: naturalProfile.value.kyc_address,
            special: 'check',
        },
        {
            label: i18n.t('account_tax_kyc_status'),
            value: naturalProfile.value.kyc_tax,
            special: 'check',
        },
        {
            label: i18n.t('account_bank_kyc_status'),
            value: naturalProfile.value.kyc_bank,
            special: 'check',
        },
        {
            label: i18n.t('ecsp_status'),
            value: naturalProfile.value.ecsp_status === EcspStatusType.EXPERT,
            condition:
                naturalProfile.value.compliance_profile ===
                PlatformComplianceProfile.ECSP,
            special: 'check',
        },
        {
            label: i18n.t('account_pep'),
            value: naturalProfile.value.pep,
            special: 'check',
        },
        {
            label: i18n.t('newsletter_consent'),
            value: naturalProfile.value.newsletter_consent,
            special: 'check',
        },
        {
            label: i18n.t('account_owner'),
            value: naturalProfile.value.owner,
            special: 'check',
        },
        {
            label: i18n.t('created_at'),
            value: moment(naturalProfile.value.created_on).format(
                'DD.MM.yyyy HH:mm'
            ),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(naturalProfile.value.updated_at).format(
                'DD.MM.yyyy HH:mm'
            ),
        },
    ]
})

const { Summary: NaturalProfileSummary } = useSummary(
    accountOverviewItemsLeft,
    {
        title: 'summary',
    }
)

const { Summary: LegalProfileSummary } = useSummary(legalProfileOverviewItems, {
    title: 'profile_legal',
})

const { Summary: TaxDetailsTable } = useSummary(taxDetailsItems)

const billingAddress = computed<Address>(() => {
    return {
        address: naturalProfile.value.address,
        zip: naturalProfile.value.postal_code,
        city: naturalProfile.value.city,
        country: naturalProfile.value.country,
        state: naturalProfile.value.state,
    }
})

const { AddressSummary: BillingAddressSummary } = useAddressSummary(
    billingAddress,
    {
        title: 'billing_address',
    }
)

const isPostalAddress = computed(() => {
    return (
        naturalProfile.value.postal_address &&
        naturalProfile.value.postal_address_postal_code &&
        naturalProfile.value.postal_address_city &&
        naturalProfile.value.country &&
        naturalProfile.value.postal_address_state
    )
})

const postalAddress = computed<Address>(() => {
    return {
        address: naturalProfile.value.postal_address,
        zip: naturalProfile.value.postal_address_postal_code,
        city: naturalProfile.value.postal_address_city,
        country: naturalProfile.value.postal_address_country,
        state: naturalProfile.value.postal_address_state,
    }
})

const { AddressSummary: PostalAddressSummary } = useAddressSummary(
    postalAddress,
    {
        title: 'postal_address',
    }
)

const bankAccountsDetailsItems = computed<
    { label: string; value: string | object | unknown; special?: string }[]
>(() => {
    return [
        {
            label: i18n.t('account_iban'),
            value:
                naturalProfile.value.iban &&
                everyFourSpace(naturalProfile.value.iban),
            copy: true,
        },
        {
            label: i18n.t('account_bic'),
            value: naturalProfile.value.bic,
            copy: true,
        },
    ]
})

const { WalletSummary } = useWalletSummary(wallet)

async function createOrder(next?: string) {
    const createOrderRequest = await apiCreateOrder(tmpOrder.value)
    const { order_uid } = createOrderRequest.data.order

    createOrderSelectUserModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'order.edit',
            params: { id: order_uid },
        })
    } else {
        if (naturalProfile.value.account_uid) {
            getAccountOrders(naturalProfile.value.account_uid)
        }
    }

    tmpOrder.value = tmpOrder.value = new CreateOrderPayload()

    toast.success(i18n.t('toast_success_order_created'))
    closeCreateOrderModal()
}
function closeCreateOrderModal() {
    tmpOrder.value = new CreateOrderPayload()
    createOrderSelectUserModalOpen.value = false
}

const { BankAccountsTable } = useBankAccountsTable(
    bankAccounts,
    openModal,
    isModalOpen
)

const externalKycDetailsItems = computed<
    {
        label: string
        value: string | object | unknown
        special?: string
        icon?: string
    }[]
>(() => {
    if (
        !naturalProfile.value.external_kyc_processes ||
        naturalProfile.value.external_kyc_processes.length === 0
    ) {
        return []
    }

    const formattedCreatedAt = naturalProfile.value.external_kyc_processes[0]
        .created_at
        ? moment(
              naturalProfile.value.external_kyc_processes[0].created_at
          ).format('DD.MM.yyyy')
        : '-'
    const formattedUpdatedAt = naturalProfile.value.external_kyc_processes[0]
        .updated_at
        ? moment(
              naturalProfile.value.external_kyc_processes[0].updated_at
          ).format('DD.MM.yyyy')
        : '-'
    const formattedNaturalProfileOverwrittenAt = naturalProfile.value
        .external_kyc_processes[0].natural_profile_overwritten_at
        ? moment(
              naturalProfile.value.external_kyc_processes[0]
                  .natural_profile_overwritten_at
          ).format('DD.MM.yyyy')
        : '-'

    const formattedKYCStatus = getEnumLabel(
        ExternalKycStatus,
        naturalProfile.value.external_kyc_processes[0].status
    )

    return [
        {
            label: i18n.t('kyc_external_id'),
            value: naturalProfile.value.external_kyc_processes[0].id,
            // condition: userStore.can('see_id_columns'),
            copy: true,
        },
        {
            label: i18n.t('kyc_external_external_id'),
            value: naturalProfile.value.external_kyc_processes[0].external_id,
            copy: true,
        },
        {
            label: i18n.t('kyc_external_status'),
            value: formattedKYCStatus,
        },
        {
            label: i18n.t('kyc_external_created_at'),
            value: formattedCreatedAt,
        },
        {
            label: i18n.t('kyc_external_updated_at'),
            value: formattedUpdatedAt,
        },
        {
            label: i18n.t('kyc_external_natural_profile_overwritten_at'),
            value: formattedNaturalProfileOverwrittenAt,
        },
        {
            label: i18n.t('kyc_external_result_summary_url'),
            value: naturalProfile.value.external_kyc_processes[0]
                .result_summary_url,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
        {
            label: i18n.t('kyc_external_result_data_package_url'),
            value: naturalProfile.value.external_kyc_processes[0]
                .result_data_package_url,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
    ]
})

const { Summary: ExternalKycSummary } = useSummary(externalKycDetailsItems, {
    title: 'external_kyc',
})

async function getKYCs(accountID: string): Promise<KYCDocument[]> {
    const KYCDocumentsRequest = await getKYCDocuments(accountID)
    return KYCDocumentsRequest.data
}

async function getECSPs(accountID: string): Promise<ECSPDocument[]> {
    const ECSPDocumentsRequest = await getECSPDocuments(accountID)
    return ECSPDocumentsRequest.data
}

async function getAccountOrders(accountID: string) {
    const orderRequest = await getOrders(
        { 'filter[account_uid]': accountID },
        1,
        10,
        [{ field: 'status', direction: 'desc' }]
    )
    userOrders.value = orderRequest.data.orders
    userOrdersNum.value = userOrders.value.length
}

async function getTransactionsCount(count: number) {
    userTransactionsCount.value = count
}

async function loadAccount() {
    const naturalProfileRequest = await getNaturalProfile(
        route.params.id as string
    )
    naturalProfile.value = naturalProfileRequest.data.natural_profile

    if (naturalProfileRequest.data.natural_profile.account_uid) {
        const accountRequest = await getAccount(
            naturalProfileRequest.data.natural_profile.account_uid
        )
        account.value = accountRequest.data.account
    }

    if (naturalProfile.value.natural_profile_uid) {
        const legalProfilesRequest = await getLegalProfilesFromNaturalProfile(
            naturalProfile.value.natural_profile_uid
        )
        const responseData = legalProfilesRequest.data

        if (responseData.length > 0) {
            legalProfile.value = responseData[0]
            showCreateButton.value = true
        } else {
            showCreateButton.value = false
        }
    }

    if (naturalProfile.value.account_uid) {
        getAccountOrders(naturalProfile.value.account_uid)
    }

    if (naturalProfile.value.platform_name) {
        const platformRequest = await getPlatform(
            naturalProfile.value.platform_name
        )
        platform.value = platformRequest.data.platform
        platformUrl.value = '/platforms/' + platform.value.platform_name
    }

    if (
        naturalProfile.value.wallet &&
        naturalProfile.value.wallet.moneyWithdrawals
    ) {
        moneyWithdrawals.value = naturalProfile.value.wallet.moneyWithdrawals
        moneyWithdrawalsNum.value = moneyWithdrawals.value.length
        wallet.value = naturalProfile.value.wallet
    }

    if (naturalProfileRequest.data.natural_profile.account_uid) {
        assignAndFilterKYCDocuments(
            naturalProfileRequest.data.natural_profile.account_uid
        )
        assignAndFilterECSPDocuments(
            naturalProfileRequest.data.natural_profile.account_uid
        )
    }

    if (platform.value.membership_management_active) {
        assignAndFilterMembershipDocuments()
    }

    signatures.value = naturalProfile.value.signatures

    if (naturalProfile.value.natural_profile_uid) {
        const voucherifyVoucherRequest = await getVoucherifyVouchers(
            {
                'filter[natural_profile_uid]':
                    naturalProfile.value.natural_profile_uid,
                'filter[hasBalance]': 'true',
            },
            1,
            10,
            [{ field: 'created_at', direction: 'desc' }]
        )
        voucherifyVouchers.value = voucherifyVoucherRequest.data.vouchers
    }
}

async function assignAndFilterKYCDocuments(accountID?: string) {
    const accountIdToUse = accountID ?? naturalProfile.value.account_uid ?? ''

    if (!accountIdToUse) {
        return
    }
    KYCDocuments.value = await getKYCs(accountIdToUse)

    addressKYCDocuments.value = KYCDocuments.value.filter(
        (document) => document.verification_type === 'kyc_address'
    )
    addressKYCDocumentsNum.value = taxKYCDocuments.value.length

    idKYCDocuments.value = KYCDocuments.value
        .filter((document) => document.verification_type === 'kyc_id')
        .sort(
            (a, b) =>
                moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
        )

    idKYCDocumentsNum.value = idKYCDocuments.value.length

    const mappedDocuments = KYCDocuments.value
        .filter((document) => document.verification_type === 'kyc_bank')
        .map((kycDocument) => {
            const bankKYCDocument = new BankKYCDocument()
            Object.assign(bankKYCDocument, kycDocument)
            return bankKYCDocument
        })

    bankKYCDocuments.value = mappedDocuments
    bankKYCDocumentsNum.value = bankKYCDocuments.value.length

    taxKYCDocuments.value = KYCDocuments.value.filter(
        (document) => document.verification_type === 'kyc_tax'
    )
    taxKYCDocumentsNum.value = taxKYCDocuments.value.length
}

async function assignAndFilterECSPDocuments() {
    const accountIdToUse = naturalProfile.value
        ? naturalProfile.value.account_uid
        : legalProfile.value.account_uid

    if (!accountIdToUse) {
        return
    }
    ECSPDocuments.value = await getECSPs(accountIdToUse)

    idECSPDocuments.value = ECSPDocuments.value.sort(
        (a, b) =>
            moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    )

    idECSPDocumentsNum.value = idECSPDocuments.value.length
}

function assignAndFilterMembershipDocuments() {
    const profileToUse = naturalProfile.value
        ? naturalProfile.value
        : legalProfile.value

    if (!profileToUse) {
        return
    }

    membershipDocuments.value = profileToUse.membership_document
    idMembershipDocuments.value = profileToUse.membership_document

    idMembershipDocuments.value = membershipDocuments.value.sort(
        (a, b) =>
            moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    )

    idMembershipDocumentsNum.value = idECSPDocuments.value.length
}

function toAccounts() {
    router.push({ name: 'accounts' })
}

function closeCreateLegalAccountModal() {
    createLegalProfileModalOpen.value = false
    tmpLegalProfile.value = new LegalProfile()
}

async function createLegalAccount(next?: string) {
    tmpLegalProfile.value.natural_profile_uid =
        naturalProfile.value.natural_profile_uid
    const createLegalProfile = await apiCreateLegalProfile(
        tmpLegalProfile.value
    )

    const legal_profile_id =
        createLegalProfile.data.legal_profile.legal_profile_uid

    createLegalProfileModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'legalProfile.edit',
            params: { id: legal_profile_id },
        })
    } else if (next && next == 'view') {
        router.push({
            name: 'legalProfile',
            params: { id: legal_profile_id },
        })
    } else {
        page.value = 1
        limit.value = parseInt(import.meta.env.VITE_DEFAULT_TABLE_LIMIT)

        await loadAccount()
    }

    tmpLegalProfile.value = new LegalProfile()

    toast.success(i18n.t('toast_success_legal_account_created'))
}

async function deleteNaturalProfile() {
    isModalOpen.value = false

    if (naturalProfile.value?.natural_profile_uid) {
        await deleteAccountNaturalGDPR(naturalProfile.value.natural_profile_uid)
        toast.success(i18n.t('toast_success_natural_profile_deleted'))
    }
    router.push({ name: 'accounts' })
}

function editNaturalProfile() {
    router.push({
        name: 'naturalProfile.edit',
        params: { id: naturalProfile.value?.natural_profile_uid },
    })
}

async function approveMembershipDocument(
    membershipDocumentIdToApprove: string
) {
    try {
        await aprroveMembershipDocument(membershipDocumentIdToApprove)
        toast.success(i18n.t('toast_success_membership_document_approved'))
    } catch (error) {
        toast.error(i18n.t('toast_error_membership_document_approved'))
        throw error
    }
}

watch(
    () => route.params.id,
    async () => {
        await loadAccount()
    }
)

onMounted(async () => {
    if (route.query.membershipDocumentIdToApprove) {
        await approveMembershipDocument(
            route.query.membershipDocumentIdToApprove as string
        )
    }

    await loadAccount()
    bankAccounts.value = naturalProfile.value.bankAccounts
})
</script>
